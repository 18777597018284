.about-us-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}
            
.aboutus, .our-core-value{
    width: 80%;
    margin-left: 6%;
    margin-top: 100px;
}

.aboutus{
    font-size: 56px;
    font-weight: 700;
}

.aboutus,.our-core-value p{
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.lets-connect {
    display: block;
    margin-top: 50px;
    width: 200px;
    height: 50px;
    background-color: #00ACC1;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: large;
    color: white;
}

.core-value{
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
}

.value {
    box-sizing: border-box;
    margin: 20px;
    padding: 10px;
    flex-basis: 200px;
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 #9a9797;
}

.value:first-child{
    margin-left: 0;
}

.value-svg{
    margin-top: 20px;
    width: 100px;
    height: 80px;
}

@media (max-width: 1480px){
    .value:first-child{
        margin-left: 20px;
    }
}

@media (max-width: 450px) {
    .about-us-container{
        text-align: center;
        align-items: center;
    }

    .our-core-value{
        margin-left: 0;
    }

    .value{
        flex-grow: 1;
    }

    .aboutus{
        margin-left: 0;
    }

    .lets-connect{
        margin-top: 30px;
        display: inline;
    }
}