.footer-container-root{
    width: 100%;
    background: #4D5155;
    color: white;
    font-family: 'Poppins',sans-serif;
}

.footer-container{
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 50px;
}

.top-content{
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    justify-content: space-around;
}

.quick-links a{
    text-decoration: none;
    display: block;
    color: #a9a9a9;
    font-size: 20px;
    font-weight: 500;
}

.bottom-content{
    margin-top: 30px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    line-height: 0;
    border-top: 0.1px solid white;
}

.footer-container span{
    text-decoration:underline;
    color: #00ACC1;
    cursor: pointer;
}

.footer-lets-connect div {
    color: #a9a9a9;
}

@media (max-width: 900px) {
    .bottom-content{
        flex-direction: column;
        margin-top: 50px;
    }

    .bottom-content p{
        line-height: 20px;
    }
}

@media (max-width: 600px){
    .footer-lets-connect{
        margin-top: 20px;
    }

    .footer-container{
        text-align: center;
    }

    .top-content {
        flex-direction: column;
        margin-top: 50px;
    }
}