.faq-container{
    width: 100%;
    text-align: center;

    padding-top: 50px;
}

.faq-container > h1{
    padding-bottom: 30px;
    font-size: 46px;
    font-weight: 700;
}

.faq{
    width: 60%;
    text-align: left;

    margin-left: auto;
    margin-right: auto;

    padding-bottom: 30px;
    border-bottom: 0.5px solid grey;
    cursor: pointer;
}

.faq p{
    width: 80%;
    margin: 0;

    font-size: 18px;
}

.arrow{
    width:75px;
    height:20px;
    transition: all 0.6s ease-in-out;
}

.arrow.open{
    transform: rotate(180deg);
}

.answer{
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.answer.open{
    opacity: 1;
    max-height: 200px;
}

.question{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question h1{
    font-size: 25px;
}

@media (max-width:1000px) {
    .faq{
        width: 80%;
    }
}