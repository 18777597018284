* {
    -webkit-tap-highlight-color: transparent;
}

button, a {
    outline: none;
}

html, body {
    font-family: 'Exo 2', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
