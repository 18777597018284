.testimonial {
    display: flex;
    justify-content: center;
    width: 100%;
}

.testimonial-header h1{
    font-size: 40px;
    font-weight: 500;
}

.testimonial-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    overflow: hidden;
    width: 80%;
}

.testimonial-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.testimonial-header h1 {
    cursor: pointer;
}

.testimonial-scroll {
    display: flex;
    gap: 20px;
}

.testimonial-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
}

.testimonial-card {
    flex: 1 0 49%;

    box-sizing: border-box;

    border: 1px solid #D9D9D9;
    border-radius: 20px;

    transition: transform 0.5s ease-in-out;
    color: black;

    padding: 20px;
}


.previous-button, .nextt-button{
    width: 50px;

    box-shadow: 0px 4px 16px rgb(213 213 213 / 40%);
    text-align: center;
    margin-right: 10px;
}

.feedback{
    display: flex;
    justify-content: space-around;
}

.testimonial-card p{
    margin: 40px;
}

@media (max-width: 1230px) {
    .testimonial-card {
        flex: 1 0 48.5%;  
    }
}

@media (max-width: 580px){
    .testimonial-scroll{
        margin-bottom: 35px;
    }
}

@media (max-width: 800px) {
    .testimonial-card {
        flex: 1 0 100%;
    }

    .testimonial-scroll h1 {
        font-size: 1.5rem;
    }
}

.rating-text {
    display: inline-flex;
    align-items: center;
}

.rating-text svg {
    margin-left: 5px;
}

.name-rating{
    display: flex;
    flex-direction: row;
}
