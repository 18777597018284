.email-root-container{
    color: black;

    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;
    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.email-container{
    background-color: white;
    border-radius: 10px;
}

.email-header{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: 20px;
}

.email-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    margin: 20px;
}

.verify-button,.resend-button{
    margin: 20px;
    width: 200px;
    height: 50px;
    background-color: #00ACC1;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: large;
    color: white;
}

#otp-input{
    display: block;
    margin: 20px;
    padding: 10px;
    border: 0px;
    border-style: none;
    resize: none;
    outline: none;
    border-radius: 2px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
}

.verified-otp{
    text-align: center;
}

.dot-container{
    width: 10px;
    height: 10px;
    margin-left: 10px;
}
