.welcome-container {
    display: flex;
    background: linear-gradient(4deg, rgba(0, 172, 193, 0.00) 12.42%, rgba(0, 172, 193, 0.10) 66.32%), #FFF;
}

.quotes {
    flex-grow: 1;
    margin-left: 80px;
    margin-top: 80px;
}

.responsive-design{
    flex-grow: 1;
    margin-left: 80px;
    margin-top: 20px;
}

.quotes h1{
    font-size: 46px;
    font-weight: 700;
}

.contact-us-button {
    display: block;
    margin-top: 130px;
    width: 200px;
    height: 50px;
    background-color: #00ACC1;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    color: white;
}


.typewriter {
    font-size: 2rem;
}


.typewriter span {
    font-size: 2rem;
    min-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    border-right: 0.15em solid black;
    margin-left: 5px;
    width: fit-content;
    animation: blink 0.75s step-end infinite;
    color: #00ACC1;
}


.responsive-image {
    width: fit-content;
}

.responsive-design h2 {
    position: relative;
    left: 26%;
    padding: 10px;
    width: fit-content;
    border-radius: 20px;
    border-color: #00ACC1;
    max-width: 300px;
}

.custom-web-solution-border {
    position: absolute;

    top: -1px;
    left: -1px;
    

    stroke: #3498db;
    stroke-width: 2;
    fill: none;
    
    z-index: 4;
    animation: drawBorder 6s linear infinite 1s;
    
    width: 100%;
    max-width: 300px;
}

@keyframes blink {
    0% {
        border-right-color: black;
    }

    50% {
        border-right-color: transparent;
    }

    100% {
        border-right-color: black;
    }
}


@media (max-width: 1400px) {
    .welcome-container{
        text-align: center;
    }

    .quotes{
        margin-top: 60px;
        margin-left: 0;
    }
    
    .responsive-image {
        width: 100%;
        object-fit: contain;
    }

    .typewriter span {
        display: block;
        margin: 0 auto;
    }

    .contact-us-button{
        display: block;
        margin-top: 100px;
        width: 50%;
        margin: 0 auto;
    }

    .responsive-design {
        margin-left: 0;
    }

    .responsive-design h2{
        left: 0;
        margin: 0 auto;
    }
}

@media (max-width:850px) {
    .welcome-container{
        flex-direction: column;
    }
}

@keyframes rotateBorder {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@keyframes drawBorder {
    0% {
        stroke-dasharray: 0 800;
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dasharray: 800 800;
        stroke-dashoffset: 0;
    }
}