.price-root{
    width: 100%;
}

.price-container{
    width: 80%;

    display: flex;
    flex-direction: column;

    justify-content: center;

    margin-left: auto;
    margin-right: auto;

    margin-top: 80px;
}

.price-service{
    box-sizing: border-box;

    margin: 10px;
    padding: 20px;

    width: 100px;
    height: 100px;

    text-align: center;
    align-content: center;
    
    outline: 3px solid #d5e3e5;
    border-radius: 2px;

    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    
    flex-basis: 300px;
}

.selected{
    outline: 3px solid #00ACC1;
}

.price-content{
    cursor: pointer;
    margin-top: 30px;
}

.price-content{
    display: flex;
    flex-direction: row;
    
    width: 100%;

    justify-content: center;
    flex-wrap: wrap;
    cursor: pointer;
}

.progress-bar{
    display: block;
    width: 100%;
    height: 10px;
    background-color: #d5e3e5;
}

.step-bar{
    text-align: end;
    margin-right: 20px;
    margin-bottom: 10px;
}

.progress{
    height: 10px;
    background-color: #00ACC1;
    transition: width 1s ease-in-out;
}

.price-footer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.next-button,.back-button,.conform-button{
    width: 200px;
    height: 50px;
    background-color: #00ACC1;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: large;
    color: white;
    margin: 10px;
}

.conform-button{
    margin-left: 0;
}

.details{
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}


.fill-details {
    display: flex;
    flex-direction: column;
    flex-basis: 260px;
    flex-grow: 1;
}

.fill-details h4{
    margin-bottom: 5px;
}

.remaining-details{
    display: flex;
    flex-direction: column;
    
    padding: 20px;

    flex-grow: 1;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

#price-input{
    margin-top: 20px;
    max-width: 300px;
    padding: 10px;
    border: 0px;
    border-style: none;
    resize: none;
    border-radius: 2px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
}

#price-input:focus{
    outline: none;
}


@media (max-width: 1210px) {
    .remaining-details{
        margin-top: 50px;
    }
}