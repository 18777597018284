.challenge-container{
    text-align: center;
    position: relative;
}

.challenge-container h1{
    font-size: 30px;
    font-weight: 700;
}

.card-container{
    display: flex;
    flex-wrap: wrap;
    
    align-items: center;
    justify-content: center;
}

.card{
    margin: 10px;
    border: 1px solid #DBDDE0;
    border-radius: 5px;

    flex-basis: 400px;
    min-height: 575px;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    transform: scale(0.9);    
}

.description{
    text-align: center; 
    font-size: 1.25rem;
    line-height: 1.5; 
    padding: 20px;
    margin-top: 200px;
}

.card-scale{
    animation: card-scale 5s ease forwards;
}


.custome-design-svg {
    width: 300px;
    height: 300px;

    position: fixed;
}

.web-security-svg {
    width: 200px;
    height: 290px;
    position: fixed;
}

.responsive-design-svg{
    width: 300px;
    height: 330px;
    position: fixed;
}

@keyframes card-scale{
    2%{
        box-shadow: 2px 2px 7px 5px rgba(0, 0, 0, 0.1);
        transform: scale(1);
    }
    90%{
        box-shadow: 2px 2px 7px 5px rgba(0, 0, 0, 0.1);
        transform: scale(1);
    }
    100%{
        box-shadow: none;
        transform: scale(0.9);
    }
}