.service-root-container{
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.service-container {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
}

.service{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    justify-content: center;
    align-items: center;

    margin: 10px;
    
    height: 300px;
    flex-basis: 300px;
    
    box-shadow: 0px 0px 4px 2px #dbdde0;
    border-radius: 2px;

    cursor: pointer;
}

.service-modal{
    position: fixed;

    width: 95%;
    height: 84%;

    overflow: scroll;

    border-radius: 2px;
    box-shadow: 0px 0px 4px 2px #dbdde0;
    background-color: white;
}

.service-modal-header{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.service-modal-header h1{
    margin-left: 8px;
}

.service-modal-content{
    width: 90%;
    position: relative;

    left: 30px;
}

.get-quote{
    display: block;
    margin-top: 20px;
    width: 200px;
    height: 50px;
    background-color: #00ACC1;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: large;
    color: white;

    text-align: center;
    align-content: center;
    text-decoration: none;
}

.service-modal-header span {
    width: 50px;
    height: 50px;
    text-align: center;
    align-content: center;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.why-ui-ux{
    padding-top: 100px;
}

.service-modal-content ul{
    font-size: 20px;
}

.service-modal-header span {
    position: relative;
    font-size: 30px;
}

.service-description{
    display: flex;
    padding-top: 30px;
    width: 100%;
}

.service-description div{
    width: 40%;
}

.service-description p{
    text-align: justify;
    margin-top: 50px;
    margin-left: 100px;
    width: 60%;
    font-size: 20px;
}

.disable{
    pointer-events: none;
}

.available-soon{
    background-color: #77c458;
    color: white;
    width: 100%;
    height: 30px;
    text-align: center;
    align-content: center;
    margin-top: 10px;

    font-weight: 700;
}

.service-svg-style{
    margin: 10px;
    width: 80%;
    height: 200px;
}

@media (max-width:1100px){
    .service-description p{
        font-size: 20px;
        margin-left: 0px;
    }
}

@media (max-width:800px){
    .service-description{
        flex-direction: column;
        margin-left: 0px;
    }

    .service-description div{
        width: 100%;
    }
    
    .service-description p{
        width: 100%;
    }
    
}
