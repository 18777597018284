.connect-container{
    width: 100%;
    background: linear-gradient(4deg, rgba(0, 172, 193, 0.00) 12.42%, rgba(0, 172, 193, 0.10) 66.32%), #FFF;
    padding-top: 50px;
}

.connect-form{
    width: 80%;
    margin-left: auto;
    margin-right: auto;


    display: flex;
    justify-content: space-between;
    
    flex-wrap: wrap;
    
    gap: 12px;

    counter-reset: step;
}

.connect-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.connect-left h1{
    font-size: 46px;
    font-weight: 700;
}

.connect-right{
    margin-left: 40px;
    flex-basis: 400px;

    display: flex;
    flex-direction: column;

    position: relative;
}

.connect-right h1{
    font-size: 46px;
    font-weight: 700;
}

.connect-right p{
    margin-left: 10px;
}

#connect-right-content-container{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

#connect-right-content{
    color: white;

    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    background-color: #00ACC1;
    border-radius: 50%;
}

#connect-right-content-container h1{
    margin-left: 10px;
}

.connect-left div{
    display: flex;
    gap: 10px;
}

.connect-left div input{
    width: 80%;
    flex-grow: 1;
}

textarea{
    max-width: 100%;
    height: 200px;
}

.attachfile-icon{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.message-us{
    width: 200px;
    height: 50px;
    background-color: #00ACC1;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: large;
    color: white;
}

#connectwithus-input{
    padding: 10px;
    border: 0px;
    resize: none;
    border-radius: 2px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
}

#connectwithus-input::placeholder{
    color: grey;
}

#connectwithus-input:focus::placeholder{
    color: black;
}

#connectwithus-input:focus{
    outline: none;
}

.pdf-container{
    display: flex;
    align-items: center;

    cursor: pointer;
}

.attachfile-icon{
    width:20px;
    height:20px;
}


@media (max-width:900px) {
    .connect-left {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .connect-right{
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width:500px) {
    .connect-form{
        flex-direction: column;
    }   
}
