.get-price-container{    
    display: flex;
    justify-content: center;
    background-color: #F7F2ED;
}

.get-price-block{
    width: 80%;

    display: flex;
    justify-content: space-between;

    gap: 100px;
}

.get-price-quote{
    display: flex;
    flex-direction: column;

    justify-content:center;
    align-items: center;
}

.get-price-quote p{
    text-align: center;
    font-size: 35px;
    line-height: 1.5;   
}

.get-price-quote button{
    background-color: #a95a0b;
    border-radius: 4px;

    border-style: none;
    color: white;


    width: 300px;
    height: 50px;

    cursor: pointer;

    font-weight: 500;
    font-size: 20px;

    text-decoration: none;

    text-align: center;
    align-content: center;
}

.quote-img{
    width: 400px;
}

@media (max-width:1100px) {     
    .get-price-block{
        width: 100%;
    }   
    
    .get-price-quote p{
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.5;   
    }

    .quote-img{
        width: 200px;
    }
}

@media (max-width:600px){

    .get-price-quote p{
        width: 80%;
    }

    .quote-img{
        display: none;
    }
}