
.spinner {
    width: 50px;
    height: 50px;
    
    margin-top: 10px;
    display: none;
    
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}