.navbar {
    width: 100%;
    height: 100px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    z-index: 1000;
}

.logo-design {
    margin-left: 5%;
    width: 250px;
    cursor: pointer;
}

.navbar-items {
    width: 100%;

    display: flex;
    justify-content: end;
    margin-right: 20px;

    list-style: none;
    font-size: 25px;
}

.navbar-items a {
    margin: 0 15px;
    cursor: pointer;
    letter-spacing: 1px;
}

.intouch-button {
    width: 300px;
    height: 60%;

    background-color: #00ACC1;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    
    font-size: 20px;
    letter-spacing: 0.24px;
    font-weight: 300;

    color: white;
    margin-right: 5%;
}

.intouch-button:hover {
    background-color: #018698;
}

.hamburger{
    display: none;
}

.hamburger-link{
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
}

@media (max-width:1150px) {        
    .navbar-items, .intouch-button{
        display: none;
    }

    .logo-design{
        margin-left: 35px;
    }
    
    .hamburger a{
        display: flex;
    }
    
    .hamburger{
        display: flex;
    }

    .hamburger-link {
        background-color: white;
        
        position: absolute;
        top: 84px;
        left: 0;
        padding: 0;
    }
    
    .hamburger-link.open {
        height: 270px;
    }
    
    .hamburger-link a{
        text-decoration: none;
        font-size: 1.5rem;
        margin: 13px;
        padding-left: 35px;
        cursor: pointer;
    }    

    .hamburger-icon{
        cursor: pointer;
        height: 50px;
        margin-right: 45%;
    }
}