.challengecard-section {
    margin-top: 80px;
}

.get-your-price {
    margin-top: 100px;
}

.testimonial-section {
    margin-top: 100px;
    user-select: none;
}

.connect-with-us-section {
    margin-top: 100px;
}

.faq-section{
    margin-top: 100px;
}